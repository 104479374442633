import React, { useEffect } from "react";
import withRouter from "components/Common/withRouter";
import { del } from "../../../helpers/api_helper";
//redux
import { useNavigate, useParams } from "react-router-dom";
import { REMOVE_VEHICLE_CATEGORY } from "helpers/api_endpoints";

const RemoveVehicleCategory = () => {
    const history = useNavigate();
    const params = useParams();
    const id = params.id;

    useEffect(() => {
        del(REMOVE_VEHICLE_CATEGORY, { id })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            })
    });

    return <React.Fragment>Removed</React.Fragment>;
};

export default withRouter(RemoveVehicleCategory);