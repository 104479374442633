import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, Label, Input, Badge } from "reactstrap"
import { post, get } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ITEMS_PER_PAGE } from "helpers/pagination_helper";
import Loader from "common/loader";
import { TICKETS_SEARCH } from "helpers/api_endpoints";
//meta title
document.title = "Tickets";
const TicketsList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [ticketTypes, setTicketTypes] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        post(`${TICKETS_SEARCH}?paginate=${ITEMS_PER_PAGE}&page=${page}`, filters)
            .then(response => {
                const data = response.data;
                setTickets(data.data);
                setTotalPages(data.pagination.totalPages);
                getTicketTypes();
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status == 400) {
                    navigate('/logout');
                }
            })
    }, [page, filters]);


    const getTicketTypes = () => {
        get(`tickets/ticket-types`)
            .then(response => {
                const types = response.data;
                setTicketTypes(types);
                console.log(ticketTypes);
            })
            .catch(error => {
                if (error.response.status == 400) {
                    navigate('/logout');
                }
            })
    }

    const handleFilter = (type, value) => {
        const filtvalues = {};
        if (value) {
            switch (type) {
                case 'title':
                    setTitle(value);
                    filtvalues.title = value;
                    break;
                default:
                    break;
            }
        }
        setFilters(filtvalues);
    }

    const getType = (type) => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Tickets" />
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <br></br>
                                <div style={{ margin: "25px" }}>
                                    <div className="row">
                                        <div className="form-group col-md-3 mt-2">
                                            <Label className="form-label"><Trans>Type</Trans></Label>
                                            <select className="form-control">
                                                <option value=''></option>
                                                {ticketTypes.map((type, index) => (<option key={index} value={type.name}>{type.name}</option>))}
                                            </select>
                                        </div>
                                        <div className="form-group col-md-3 mt-2">
                                            <Label className="form-label"><Trans>Title</Trans></Label>
                                            <Input
                                                name="title"
                                                className="form-control"
                                                placeholder=""
                                                type="text"
                                                onChange={(e) => handleFilter('title', e.target.value)}
                                                onKeyDown={(e) => handleFilter('title', e.target.value)}
                                            ></Input>
                                        </div>
                                    </div>
                                </div>

                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th><Trans>ID</Trans></th>
                                                    <th><Trans>User</Trans></th>
                                                    <th><Trans>Type</Trans></th>
                                                    <th><Trans>Title</Trans></th>
                                                    <th><Trans>Message</Trans></th>
                                                    <th>#</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? <tr><th colSpan={7} className="text-center"><Loader></Loader></th></tr> : null}
                                                {
                                                    tickets.map((ticket, index) => (
                                                        <tr key={index}>
                                                            <td>{ticket.id}</td>
                                                            <td>{ticket.User ? <Badge bg="info">{ticket.User.name}</Badge> : null}</td>
                                                            <td><Badge bg="primary">{ticket.ticketType}</Badge></td>
                                                            <td>{ticket.title}</td>
                                                            <td><textarea className="form-control" rows={3} value={ticket.description} readOnly></textarea></td>
                                                            <td><Link to={`/tickets/details/${ticket.id}`}><button className="btn btn-sm btn-info"><i className="dripicons-preview"></i> &nbsp;<Trans>View</Trans></button></Link> &nbsp;
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <br></br>
                                    <div className="text-center">
                                        {/* Pagination controls */}
                                        <button className="btn btn-sm btn-dark" onClick={handlePrevPage} disabled={page === 1}>
                                            <Trans>Previous</Trans>
                                        </button>&nbsp;
                                        <button className="btn btn-sm btn-dark" onClick={handleNextPage} disabled={page === totalPages}>
                                            <Trans>Next</Trans>
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TicketsList
