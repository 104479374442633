import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, Alert, Label, Input, Badge } from "reactstrap"
import Select from "react-select";
import { get, post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ITEMS_PER_PAGE } from "helpers/pagination_helper";
import Loader from "common/loader";
import { ADDRESS_SEARCH, USERS_LIST } from "helpers/api_endpoints";
import UsersList from "pages/Users/UsersList";
//meta title
document.title = "User Address";
const AddressList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [addresses, setAddresses] = useState([]);
    const [type, setType] = useState('');
    const [status, setStatus] = useState('');
    const [userId, setUser] = useState({});
    const [users, setUsers] = useState([]);
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [successMsg, setsuccessMsg] = useState('');

    useEffect(() => {
        successMessage();
        get(`${USERS_LIST}`)
            .then(response => {
                const data = response.data;
                const users = data.map(el=>{
                    return {value:el.id,label:el.name}
                })
                setUsers(users);
            })
            .catch(error => {
            })
        post(`${ADDRESS_SEARCH}?paginate=${ITEMS_PER_PAGE}&page=${page}`, filters)
            .then(response => {
                const data = response.data;
                setAddresses(data.data);
                setTotalPages(data.pagination.totalPages);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    navigate('/logout');
                }
            })
    }, [page, filters]);


    const successMessage = () => {
        if (location.state) {
            setsuccessMsg(location.state.successMsg);
            setTimeout(() => {
                setsuccessMsg('');
            }, 4000)
        }
    };

    const handleFilter = (type, value) => {
        const filtvalues = {};
        if (value) {
            switch (type) {
                case 'type':
                    setType(value);
                    filtvalues.type = value;
                    break;
                case 'status':
                    setStatus(value);
                    filtvalues.status = value;
                    break;
                case 'user':
                    setUser(value);
                    filtvalues.userId = value;
                    break;
                default:
                    break;
            }
        }
        setFilters(filtvalues);
    }

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Address" />
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <br></br>
                                {successMsg ? <Alert style={{ margin: "25px" }} color="success">{successMsg}</Alert> : null}
                                <CardTitle>
                                    <Link to="/addresses/add" >
                                        <button style={{ float: "right", marginRight: "20px" }} className="btn btn-sm btn-primary"><i className="dripicons-plus"></i> &nbsp;<Trans>Create</Trans></button>
                                    </Link>
                                </CardTitle>

                                <div style={{ margin: "25px" }}>
                                    <Row>
                                        <div className="form-group col-md-4 mt-2">
                                            <Label className="form-label"><Trans>Type</Trans></Label>
                                            <Input
                                                name="type"
                                                // value={name}
                                                className="form-control"
                                                placeholder="Enter "
                                                type="text"
                                                onChange={(e) => handleFilter('type', e.target.value)}
                                                onKeyDown={(e) => handleFilter('type', e.target.value)}
                                            ></Input>
                                        </div>
                                        <div className="form-group col-md-4 mt-2">
                                            <Label className="form-label"><Trans>Status</Trans></Label>
                                            <Input
                                                name="status"
                                                // value={name}
                                                className="form-control"
                                                placeholder="Enter "
                                                type="text"
                                                onChange={(e) => handleFilter('status', e.target.value)}
                                                onKeyDown={(e) => handleFilter('status', e.target.value)}
                                            ></Input>
                                        </div>
                                        <div className="form-group col-md-4 mt-2">
                                            <Label className="form-label"><Trans>User</Trans></Label>
                                            <Select
                                                name="user"
                                                options={users} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('user', e.value)}
                                            />
                                        </div>
                                    </Row>
                                </div>


                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th><Trans>ID</Trans></th>
                                                    <th><Trans>Country</Trans></th>
                                                    <th><Trans>Emirate</Trans></th>
                                                    <th><Trans>latitude</Trans></th>
                                                    <th><Trans>longitude</Trans></th>
                                                    <th><Trans>phone</Trans></th>
                                                    <th><Trans>Street</Trans></th>
                                                    <th><Trans>Building</Trans></th>
                                                    <th><Trans>FlatNo</Trans></th>
                                                    <th><Trans>Additional</Trans></th>
                                                    <th><Trans>Type</Trans></th>
                                                    <th><Trans>Status</Trans></th>
                                                    <th><Trans>Created At</Trans></th>
                                                    <th>#</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? <tr><th colSpan={6} className="text-center"><Loader></Loader></th></tr> : null}
                                                {
                                                    addresses.map((address, index) => (
                                                        <tr key={index}>
                                                            <td>{address.id}</td>
                                                            <td>{address.country.name}</td>
                                                            <td>{address.emirateId}</td>
                                                            <td>{address.latitude}</td>
                                                            <td>{address.longitude}</td>
                                                            <td>{address.phone}</td>
                                                            <td>{address.street}</td>
                                                            <td>{address.building}</td>
                                                            <td>{address.flatNo}</td>
                                                            <td>{address.additional}</td>
                                                            <td>{(address.type == 1) ? <Badge className="bg-success"><Trans>Pick up</Trans></Badge> : <Badge className="bg-danger"><Trans>Delivery</Trans></Badge>}</td>
                                                            <td>{(address.status == 1) ? <Badge className="bg-success"><Trans>Active</Trans></Badge> : <Badge className="bg-danger"><Trans>Non Active</Trans></Badge>}</td>
                                                            <td>{address.createdAt}</td>
                                                            <td>
                                                                <Link to={`/address/edit/${address.id}`}><button className="btn btn-sm btn-info"><i className="dripicons-pencil"></i> &nbsp;<Trans>Edit</Trans></button></Link> &nbsp;
                                                                <Link to={`/address/remove/${address.id}`}><button className="btn btn-sm btn-danger" ><i className="dripicons-trash"></i> &nbsp;<Trans>Remove</Trans> </button></Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <br></br>
                                    <div className="text-center">
                                        {/* Pagination controls */}
                                        <button className="btn btn-sm btn-dark" onClick={handlePrevPage} disabled={page === 1}>
                                            <Trans>Previous</Trans>
                                        </button>&nbsp;
                                        <button className="btn btn-sm btn-dark" onClick={handleNextPage} disabled={page === totalPages}>
                                            <Trans>Next</Trans>
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddressList
