import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input, Label, Button, Form } from "reactstrap";
import { get, put } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { error } from "toastr";
import { Trans } from 'react-i18next';
import { Link, Route, useNavigate, useParams } from "react-router-dom";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "common/loader";
import { SHOW_USER, UPDATE_USER } from "helpers/api_endpoints";


//meta title
document.title = "Edit User";

const EditUser = () => {
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [id, setid] = useState('');
    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [mobile, setmobile] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        get(`${SHOW_USER}/${params.id}`)
            .then(response => {
                const user = response.data;
                setid(params.id);
                setemail(user.email);
                setname(user.name);
                setmobile(user.mobile);
                setLoading(false);
            })
            .catch(error => {
                navigate('/users');
            })
    }, []);

    const handleSubmit = () => {
        put(UPDATE_USER, { id, name, email, mobile })
            .then(response => {
                // console.log(response)
                if (response.code == 200) {
                    navigate('/users', { state: { successMsg: 'User Updated SuccessFully' } });
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Users" breadcrumbItem="Edit User" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        {loading ? <Loader></Loader> :
                                            <Row>
                                                <div className="form-group col-md-6 mt-2">
                                                    <Label className="form-label"><Trans>Name</Trans></Label>
                                                    <Input
                                                        name="username"
                                                        value={name}
                                                        className="form-control"
                                                        placeholder="Enter User Name"
                                                        type="text"
                                                        onChange={(e) => setname(e.target.value)}
                                                        required ></Input>
                                                    <Input type="hidden" name="id"></Input>
                                                </div>

                                                <div className="form-group col-md-6 mt-2">
                                                    <Label className="form-label"><Trans>Email</Trans></Label>
                                                    <Input
                                                        name="email"
                                                        value={email}
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        type="email"
                                                        onChange={(e) => setemail(e.target.value)}
                                                        required ></Input>
                                                </div>

                                                <div className="form-group col-md-6 mt-2">
                                                    <Label className="form-label"><Trans>Mobile</Trans></Label>
                                                    <Input
                                                        name="mobile"
                                                        value={mobile}
                                                        className="form-control"
                                                        placeholder="Enter Mobile"
                                                        type="text"
                                                        onChange={(e) => setmobile(e.target.value)} required
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-6 mt-2">
                                                    <Label className="form-label"><Trans>Password</Trans></Label>
                                                    <Input
                                                        name="password"
                                                        // value={name}
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        type="password"
                                                        onChange={(e) => setpassword(e.target.value)}
                                                    ></Input>
                                                </div>
                                                <div className="text-center  mt-4">
                                                    <Button type="submit" color="danger">
                                                        <Trans>Submit</Trans>
                                                    </Button>
                                                </div>
                                            </Row>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditUser
