import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form } from "reactstrap";
import { get, put, post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import Loader from "common/loader";
import UploadImage from "common/uploadImage";
import { SHOW_OFFER, UPDATE_OFFER, ADD_OFFER } from "helpers/api_endpoints";

//meta title
document.title = "Edit Offer";

const EditOffer = () => {

    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [id, setid] = useState('');
    const [status, setStatus] = useState(0);
    const [name, setname] = useState('');
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [terms, setTerms] = useState('');
    const [anew, setNew] = useState(0);
    const [expiryDate, setexpiryDate] = useState('');
    const [oldPrice, setoldPrice] = useState('');
    const [offerPrice, setofferPrice] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        get(`${SHOW_OFFER}/${params.id}`)
            .then(response => {
                const offer = response.data;
                setid(params.id);
                setname(offer.name);
                setStatus(offer.status);
                setText(offer.text);
                setImage(offer.image);
                setTitle(offer.title);
                setTerms(offer.terms);
                setexpiryDate(new Date(offer.expiryDate).toISOString().split('T')[0]);
                setofferPrice(offer.offerPrice);
                setoldPrice(offer.oldPrice);
                setDescription(offer.description);
                setLoading(false);
            })
            .catch(error => {
                navigate('/offers');
            })
    }, []);

    const handleSubmit = () => {
        console.log()
        const imagePath = document.getElementById('imagePath').value;
        const config = { id, name, status, text, title, expiryDate, offerPrice, oldPrice, terms, description, image: imagePath };
        if(anew == 1)
        {
            delete config.id;
            post(ADD_OFFER, config)
            .then(response => {
                if (response.code == 200) {
                    navigate('/offers', { state: { successMsg: 'Offer Added SuccessFully' } });
                }
            })
            .catch(error => {
                console.log(error.response);
            })
        }
        else
        {
            put(UPDATE_OFFER, config)
                .then(response => {
                    if (response.code == 200) {
                        navigate('/offers', { state: { successMsg: 'Offer Updated SuccessFully' } });
                    }
                })
                .catch(error => {
                    console.log(error.response);
                })
        }
    }

    const handleToggleCheckBoxStatus = () => {
        if (status) {
            setStatus(0);
        }
        else {
            setStatus(1)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Offers" breadcrumbItem="Edit Offer" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        {loading ? <Loader></Loader> :
                                            <Row>
                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Name</Trans></Label>
                                                    <Input
                                                        name="name"
                                                        value={name}
                                                        className="form-control"
                                                        placeholder="Enter  Name"
                                                        type="text"
                                                        onChange={(e) => setname(e.target.value)}
                                                        required ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Title</Trans></Label>
                                                    <Input
                                                        name="title"
                                                        value={title}
                                                        className="form-control"
                                                        placeholder="Enter Title"
                                                        type="text"
                                                        onChange={(e) => setTitle(e.target.value)}
                                                        required ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Text</Trans></Label>
                                                    <Input
                                                        name="text"
                                                        value={text}
                                                        className="form-control"
                                                        placeholder="Enter Text"
                                                        type="text"
                                                        onChange={(e) => setText(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Terms</Trans></Label>
                                                    <Input
                                                        name="terms"
                                                        value={terms}
                                                        className="form-control"
                                                        placeholder="Enter Terms"
                                                        type="text"
                                                        onChange={(e) => setTerms(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Offer Price</Trans></Label>
                                                    <Input
                                                        name="offerPrice"
                                                        value={offerPrice}
                                                        className="form-control"
                                                        placeholder="Enter Text"
                                                        type="number"
                                                        onChange={(e) => setofferPrice(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Old Price</Trans></Label>
                                                    <Input
                                                        name="oldPrice"
                                                        value={oldPrice}
                                                        className="form-control"
                                                        placeholder="Enter Text"
                                                        type="number"
                                                        onChange={(e) => setoldPrice(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Expiry Date</Trans></Label>
                                                    <Input
                                                        name="expiryDate"
                                                        value={expiryDate}
                                                        className="form-control"
                                                        placeholder="Enter Expiry Date"
                                                        type="date"
                                                        onChange={(e) => setexpiryDate(e.target.value)}
                                                        required ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Description</Trans></Label>
                                                    <textarea name="description"
                                                        className="form-control"
                                                        value={description}
                                                        placeholder="Enter Description"
                                                        onChange={(e) => setDescription(e.target.value)}></textarea>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Status</Trans></Label>
                                                    <div className="form-check form-check-end">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value={1}
                                                            id="defaultCheck2"
                                                            checked={status == 1}
                                                            onClick={handleToggleCheckBoxStatus}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck2"
                                                        >
                                                            <Trans>Active</Trans>
                                                        </label>
                                                    </div>
                                                </div>


                                                <UploadImage state={{ InputLabel: 'Image', filePath: image, type: 'offers' }}></UploadImage>

                                                <div className="mt-4">
                                                    <Button type="submit" color="danger">
                                                        <Trans>Submit</Trans>
                                                    </Button>
                                                    <Button type="submit" onClick={(e) => setNew(1)} color="info">
                                                        <Trans>Submit As New</Trans>
                                                    </Button>
                                                </div>
                                            </Row>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditOffer
