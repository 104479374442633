import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input, Label, Button, Form, Alert } from "reactstrap";
import { post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { Link, Route, useNavigate } from "react-router-dom";
import { ADD_USER } from "helpers/api_endpoints";


//meta title
document.title = "Add User";

const AddUser = () => {

    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [mobile, setmobile] = useState('');
    const [errorMsg, seterrorMsg] = useState('');
    const navigate = useNavigate();

    const handleSubmit = () => {
        post(ADD_USER, { name, email, password, mobile })
            .then(response => {
                // console.log(response)
                if (response.code == 201) {
                    navigate('/users', { state: { successMsg: 'User Added SuccessFully' } });
                }
            })
            .catch(error => {
                seterrorMsg('User Creation Failed');
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Users" breadcrumbItem="Add User" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}
                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Name</Trans></Label>
                                                <Input
                                                    name="username"
                                                    // value={name}
                                                    className="form-control"
                                                    placeholder="Enter User Name"
                                                    type="text"
                                                    onChange={(e) => setname(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Email</Trans></Label>
                                                <Input
                                                    name="email"
                                                    // value={name}
                                                    className="form-control"
                                                    placeholder="Enter Email"
                                                    type="email"
                                                    onChange={(e) => setemail(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Mobile</Trans></Label>
                                                <Input
                                                    name="mobile"
                                                    // value={name}
                                                    className="form-control"
                                                    placeholder="Enter Mobile"
                                                    type="text"
                                                    onChange={(e) => setmobile(e.target.value)} required
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Password</Trans></Label>
                                                <Input
                                                    name="password"
                                                    // value={name}
                                                    className="form-control"
                                                    placeholder="Enter Email"
                                                    type="password"
                                                    onChange={(e) => setpassword(e.target.value)} required
                                                ></Input>
                                            </div>
                                        </Row>
                                        <div className="text-center  mt-4">
                                            <Button type="submit" color="danger">
                                                <Trans>Submit</Trans>
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddUser
