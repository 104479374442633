import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { Button } from "reactstrap";
const SubmitButton = props => {
    const [text, setText] = useState('');
    useEffect(() => {
        if (props.state) {
            setText(props.state.text);
        }
    }, []);
    return (
        <React.Fragment>
            <Button type="submit" style={{ background: "#FE9C19", border: "none" }}>
                <Trans>{text}</Trans>
            </Button>
        </React.Fragment>
    )
}

export default SubmitButton
