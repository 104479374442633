import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input, Label, Button, Form, Alert } from "reactstrap";
import { get, post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import UploadImage from "common/uploadImage";
import { ADD_ORDER, ADDRESS_TYPE, CATEGORIES_SEARCH, COUNTRIES_SEARCH, DRIVERS_SEARCH, ITEMS_SEARCH, SERVICES_SEARCH, SHIPMENT_TYPES_SEARCH, VEHICLE_CATEGORY_SEARCH, STATUS_SEARCH, USERS_SEARCH } from "helpers/api_endpoints";
import Select from "react-select";
import OrderDet from "./OrderDet";


//meta title
document.title = "Add Order";

const AddOrder = () => {

    const [currentStatusId, setCurrentStatusId] = useState(0);
    const [userId, setUserId] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const [countryId, setCountryId] = useState(181);
    const [driverId, setDriverId] = useState(0);
    const [vehicleCategoryId, setVehicleCategoryId] = useState(0);
    const [addressFromId, setAddressFromId] = useState(0);
    const [addressToId, setAddressToId] = useState(0);
    const [shipmentTypeId, setShipmentTypeId] = useState(0);
    const [shipmentTypeOptions, setshipmentTypeOptions] = useState([]);
    const [orderDate, setOrderDate] = useState('2024-01-01');
    const [items, setItems] = useState([{itemId:0,quantity:0,price:0}]);
    const [services, setServices] = useState([{serviceId:0,quantity:0,price:0}]);
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [countries, setCountries] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [vehicleCategories, setVehicleCategories] = useState([]);
    const [addressesFrom, setAddressesFrom] = useState([]);
    const [addressesTo, setAddressesTo] = useState([]);
    const [shipmentTypes, setShipmentTypes] = useState([]);
    const [statues, setStatues] = useState([]);
    const [filters, setFilters] = useState([]);
    const [errorMsg, seterrorMsg] = useState('');
    const [update, setUpdate] = useState(0);
    const [image, setImage] = useState('');
    // const [loading, setLoading] = useState(true);


    const navigate = useNavigate();

    useEffect(() => {
        post(ADDRESS_TYPE+'/0')
            .then(response => {
                const temp = response.data.map(el=>{
                    return {value:el.id,label:el.title}
                });
                setAddressesFrom(temp);
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(ADDRESS_TYPE+'/1')
            .then(response => {
                const temp = response.data.map(el=>{
                    return {value:el.id,label:el.title}
                });
                setAddressesTo(temp);
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(CATEGORIES_SEARCH)
            .then(response => {
                setCategories(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(DRIVERS_SEARCH)
            .then(response => {
                setDrivers(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(DRIVERS_SEARCH)
            .then(response => {
                setDrivers(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(COUNTRIES_SEARCH)
            .then(response => {
                setCountries(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(USERS_SEARCH)
            .then(response => {
                setUsers(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(VEHICLE_CATEGORY_SEARCH)
            .then(response => {
                setVehicleCategories(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(SHIPMENT_TYPES_SEARCH)
            .then(response => {
                setShipmentTypes(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
        post(STATUS_SEARCH)
            .then(response => {
                setStatues(response.data.map(el=>{
                    return {value:el.id,label:el.name}
                }));
            })
            .catch(error => {
                seterrorMsg('Loading Failed');
            })
    }, []);


    const handleSubmit = () => {
        post(ADD_ORDER, { categoryId, vehicleCategoryId, userId, currentStatusId, addressFromId, addressToId, shipmentTypeId, shipmentTypeOptions, orderDate, items, services, countryId, driverId })
            .then(response => {
                if (response.code == 201) {
                    navigate('/orders', { state: { successMsg: 'Added SuccessFully' } });
                }
            })
            .catch(error => {
                seterrorMsg('Creation Failed');
            })
    }

    const handleFilter = (type, value) => {
        const filtvalues = {};
        if (value) {
            switch (type) {
                case 'category':
                    setCategoryId(value);
                    filtvalues.category = value;
                    break;
                case 'vehicleCategory':
                    setVehicleCategoryId(value);
                    break;
                case 'addressfrom':
                    setAddressFromId(value);
                    filtvalues.addressFrom = value;
                    break;
                case 'addressTo':
                    setAddressToId(value);
                    filtvalues.addressTo = value;
                    break;
                case 'driverId':
                    setDriverId(value);
                    break;
                case 'countryId':
                    setCountryId(value);
                    break;
                case 'shipmentType':
                    setShipmentTypeId(value);
                    break;
                case 'currentStatusId':
                    setCurrentStatusId(value);
                    break;
                case 'orderDate':
                    setOrderDate(value);
                    break;
                case 'user':
                    setUserId(value);
                    break;
                default:
                    break;
            }
        }
        setFilters(filtvalues);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Items" breadcrumbItem="Add Item" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Delivery Type</Trans></Label>
                                                <Select
                                                name="categoryId"
                                                options={categories} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('category', e.value)}
                                            />
                                            </div>
                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Vehicle Type</Trans></Label>
                                                <Select
                                                name="vehicleCategoryId"
                                                options={vehicleCategories} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('vehicleCategory', e.value)}
                                            />
                                            </div>
                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Customer</Trans></Label>
                                                <Select
                                                name="userId"
                                                options={users} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('user', e.value)}
                                            />
                                            </div>
                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Date</Trans></Label>
                                                <Input
                                                name="orderDate"
                                                value={orderDate}
                                                className="form-control"
                                                placeholder="Enter Date "
                                                type="date"
                                                onChange={(e) => handleFilter('orderDate', e.target.value)}
                                            ></Input>
                                            </div>


                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Address From</Trans></Label>
                                                <Select
                                                name="addressFromId"
                                                options={addressesFrom} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('addressfrom', e.value)}
                                            />
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Address To</Trans></Label>
                                                <Select
                                                name="addressToId"
                                                options={addressesTo} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('addressTo', e.value)}
                                            />
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Driver</Trans></Label>
                                                <Select
                                                name="driverId"
                                                options={drivers} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('driverId', e.value)}
                                            />
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Shipment Type</Trans></Label>
                                                <Select
                                                name="shipmentTypeId"
                                                options={shipmentTypes} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('shipmentType', e.value)}
                                            />
                                            </div>


                                            <div className="form-group col-md-2 mt-2">
                                                <Label className="form-label"><Trans>Status</Trans></Label>
                                                <Select
                                                name="currentStatusId"
                                                options={statues} 
                                                className="form-control select2"
                                                onChange={(e) => handleFilter('currentStatusId', e.value)}
                                            />
                                            </div>

                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                {items.map((item,index) => (
                                    <div key={index}>
                                        <OrderDet type="item" data={item} />
                                    </div>

                                ))}
                                <div className="mt-4">
                                    <Button type="button" color="info" onClick={ e => {e.preventDefault()
                                                                                items.push({itemId:0,quantity:0,price:0})
                                                                                setUpdate(Math.random())}}>
                                        <Trans>Add</Trans>
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                {services.map((service,index) => (
                                    <div key={index}>
                                        <OrderDet type="service" data={service} />
                                    </div>

                                ))}
                                <div className="mt-4">
                                    <Button type="button" color="info" onClick={ e => {e.preventDefault()
                                                                                services.push({serviceId:0,quantity:0,price:0})
                                                                                setUpdate(Math.random())}}>
                                        <Trans>Add</Trans>
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                        <div className="mt-4">
                                            <Button type="submit" color="danger">
                                                <Trans>Submit</Trans>
                                            </Button>
                                        </div>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddOrder
