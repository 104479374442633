import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, Alert, Label, Input, Badge } from "reactstrap"
import { post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ITEMS_PER_PAGE } from "helpers/pagination_helper";
import Loader from "common/loader";
import { ImageBaseUrl } from "helpers/file_upload_helper";
import { SHIPMENT_TYPES_SEARCH } from "helpers/api_endpoints";
//meta title
document.title = "Shipment Types";
const ShipmentTypeList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(true);
    const [shipmentTypes, setShipmentTypes] = useState([]);
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [successMsg, setsuccessMsg] = useState('');

    useEffect(() => {
        successMessage();
        post(`${SHIPMENT_TYPES_SEARCH}?paginate=${ITEMS_PER_PAGE}&page=${page}`, filters)
            .then(response => {
                const data = response.data;
                setShipmentTypes(data.data);
                setTotalPages(data.pagination.totalPages);
                setPage(data.pagination.currentPage);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status == 400) {
                    navigate('/logout');
                }
            })
    }, [page, filters]);

    const successMessage = () => {
        if (location.state) {
            setsuccessMsg(location.state.successMsg);
            setTimeout(() => {
                location.state.successMessage = '';
                setsuccessMsg('');
            }, 4000)
        }
    };

    const handleFilter = (type, value) => {
        const filtvalues = {};
        if (value) {
            switch (type) {
                case 'name':
                    setName(value);
                    filtvalues.name = value;
                    break;
                default:
                    break;
            }
        }
        setFilters(filtvalues);
    }

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Shipment Types" />
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <br></br>
                                {successMsg ? <Alert style={{ margin: "25px" }} color="success">{successMsg}</Alert> : null}
                                <CardTitle>
                                    <Link to="/shipment-types/add" >
                                        <button style={{ float: "right", marginRight: "20px" }} className="btn btn-sm btn-primary"><i className="dripicons-plus"></i> &nbsp;<Trans>Create</Trans></button>
                                    </Link>
                                </CardTitle>

                                <div style={{ margin: "25px" }}>
                                    <div className="row">
                                        <div className="form-group col-md-3 mt-2">
                                            <Label className="form-label"><Trans>Name</Trans></Label>
                                            <Input
                                                name="name"
                                                className="form-control"
                                                placeholder=""
                                                type="text"
                                                onChange={(e) => handleFilter('name', e.target.value)}
                                                onKeyDown={(e) => handleFilter('name', e.target.value)}
                                            ></Input>
                                        </div>
                                    </div>
                                </div>


                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th><Trans>ID</Trans></th>
                                                    <th className="text-center"><Trans>Image</Trans></th>
                                                    <th><Trans>Name</Trans></th>
                                                    <th><Trans>Title</Trans></th>
                                                    <th><Trans>Sub Title</Trans></th>
                                                    <th><Trans>Status</Trans></th>
                                                    <th>#</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? <tr><th colSpan={7} className="text-center"><Loader></Loader></th></tr> : null}
                                                {
                                                    shipmentTypes.map((type, index) => (
                                                        <tr key={index}>
                                                            <td>{type.id}</td>
                                                            <td className="text-center"><img style={{ background: "#f5f5f5", padding: "15px", borderRadius: "5px" }} src={`${type.image}`} width={70}></img></td>
                                                            <td>{type.name}</td>
                                                            <td>{type.title}</td>
                                                            <td>{type.subTitle}</td>
                                                            <td>{(type.status == 1) ? <Badge className="bg-success"><Trans>Active</Trans></Badge> : <Badge className="bg-danger"><Trans>Non Active</Trans></Badge>}</td>
                                                            <td><Link to={`/shipment-types/edit/${type.id}`}><button className="btn btn-sm btn-info"><i className="dripicons-pencil"></i> &nbsp;<Trans>Edit</Trans></button></Link> &nbsp;
                                                                <Link to={`/shipment-types/remove/${type.id}`}><button className="btn btn-sm btn-danger" ><i className="dripicons-trash"></i> &nbsp;<Trans>Remove</Trans> </button></Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <br></br>
                                    <div className="text-center">
                                        {/* Pagination controls */}
                                        <button className="btn btn-sm btn-dark" onClick={handlePrevPage} disabled={page === 1}>
                                            <Trans>Previous</Trans>
                                        </button>&nbsp;
                                        <button className="btn btn-sm btn-dark" onClick={handleNextPage} disabled={page === totalPages}>
                                            <Trans>Next</Trans>
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment >
    )
}

export default ShipmentTypeList
