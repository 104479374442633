import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form, Alert } from "reactstrap";
import { post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { ADD_COUNTRY } from "helpers/api_endpoints";


//meta title
document.title = "Add Country";

const AddCountry = () => {

    const [name, setname] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [symbol, setSymbol] = useState('');
    const [status, setStatus] = useState(0);
    const [errorMsg, seterrorMsg] = useState('');
    const navigate = useNavigate();

    const handleSubmit = () => {
        post(ADD_COUNTRY, { name, countryCode, symbol, status })
            .then(response => {
                if (response.code == 201) {
                    navigate('/countries', { state: { successMsg: 'Country Added SuccessFully' } });
                }
            })
            .catch(error => {
                seterrorMsg('Country Creation Failed');
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Countries" breadcrumbItem="Add Country" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}
                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Name</Trans></Label>
                                                <Input
                                                    name="name"
                                                    // value={name}
                                                    className="form-control"
                                                    placeholder="Enter  Name"
                                                    type="text"
                                                    onChange={(e) => setname(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Country Code</Trans></Label>
                                                <Input
                                                    name="countryCode"
                                                    // value={name}
                                                    className="form-control"
                                                    placeholder="Enter Country Code"
                                                    type="text"
                                                    onChange={(e) => setCountryCode(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Symbol</Trans></Label>
                                                <Input
                                                    name="symbol"
                                                    // value={name}
                                                    className="form-control"
                                                    placeholder="Enter Symbol"
                                                    type="text"
                                                    onChange={(e) => setSymbol(e.target.value)} required
                                                ></Input>
                                            </div>


                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Status</Trans></Label>
                                                <select className="form-control" onChange={(e) => setStatus(e.target.value)}>
                                                    <option value=''></option>
                                                    <option value={1} selected={status == 1}><Trans>Active</Trans></option>
                                                    <option value={0} selected={status == 0}><Trans>Non Active</Trans></option>
                                                </select>
                                            </div>

                                        </Row>
                                        <div className="text-center  mt-4">
                                            <Button type="submit" color="danger">
                                                <Trans>Submit</Trans>
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddCountry
