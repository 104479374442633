import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form, Alert } from "reactstrap";
import { post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { ADD_DRIVER, ADD_USER, ASSIGN_ROLE } from "helpers/api_endpoints";


//meta title
document.title = "Add Driver";

const AddDriver = () => {

    const [status, setStatus] = useState(1);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [userId, setUserId] = useState('');
    const [emirateId, setEmirateid] = useState('');
    const [driverName, setDriverName] = useState('');
    const [licenseNo, setLicenseNo] = useState('');
    const [licenseExpiry, setLicenseExpiry] = useState('');
    const [cargoEmployee, setCargoEmployee] = useState(0);
    const [errorMsg, seterrorMsg] = useState('');
    const navigate = useNavigate();

    useEffect(() => { }, []);

    const handleSubmit = () => {
        saveUserData();
    }

    const handleToggleCheckBox = (inputFunction) => {
        switch (inputFunction) {
            case 'setStatus':
                if (status == 1) {
                    setStatus(0);
                }
                else {
                    setStatus(1);
                }
                break;
            case 'setCargoEmployee':
                if (cargoEmployee == 1) {
                    setCargoEmployee(0);
                }
                else {
                    setCargoEmployee(1);
                }
                break;
            default:
                break;
        }
    }

    const saveUserData = () => {
        post(ADD_USER, { name: username, email, password, mobile, emirateId, status: 1 })
            .then(response => {
                if (response.code == 201) {
                    const userData = response.data;
                    if (userData.id) {
                        assignDriverRole(userData);
                        saveDriverData(userData);
                    }
                    else {
                        seterrorMsg('Driver Creation Failed');
                    }
                }
            })
            .catch(error => {
                seterrorMsg('Driver Creation Failed');
            })
    }

    const saveDriverData = (userData) => {
        post(ADD_DRIVER, { userId: userData.id, name: driverName, licenseNo, licenseExpiry, cargoEmployee, status })
            .then(response => {
                if (response.code == 201) {
                    navigate('/drivers', { state: { successMsg: 'Driver Added SuccessFully' } });
                }
            })
            .catch(error => {
                seterrorMsg('Driver Creation Failed');
            })
    }

    const assignDriverRole = (userData) => {
        post(ASSIGN_ROLE, { UserId: userData.id, RoleId: 3 })
            .then(response => {
            })
            .catch(error => {
                seterrorMsg('Driver Creation Failed');
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Drivers" breadcrumbItem="Add Driver" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>User Name</Trans></Label>
                                                <Input
                                                    name="username"
                                                    value={username}
                                                    className="form-control"
                                                    placeholder="Enter User Name"
                                                    type="text"
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Email</Trans></Label>
                                                <Input
                                                    name="email"
                                                    value={email}
                                                    className="form-control"
                                                    placeholder="Enter Email"
                                                    type="email"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Mobile</Trans></Label>
                                                <Input
                                                    name="mobile"
                                                    value={mobile}
                                                    className="form-control"
                                                    placeholder="Enter Mobile"
                                                    type="text"
                                                    onChange={(e) => setMobile(e.target.value)} required
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Password</Trans></Label>
                                                <Input
                                                    name="password"
                                                    className="form-control"
                                                    placeholder="Enter Email"
                                                    type="password"
                                                    onChange={(e) => setPassword(e.target.value)} required
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Driver Name</Trans></Label>
                                                <Input
                                                    name="driverName"
                                                    value={driverName}
                                                    className="form-control"
                                                    placeholder="Enter Driver Name"
                                                    type="text"
                                                    onChange={(e) => setDriverName(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Emirates Id</Trans></Label>
                                                <Input
                                                    name="emirateId"
                                                    value={emirateId}
                                                    className="form-control"
                                                    placeholder="Enter Emirates Id"
                                                    type="text"
                                                    onChange={(e) => setEmirateid(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>License Number</Trans></Label>
                                                <Input
                                                    name="licenseNo"
                                                    value={licenseNo}
                                                    className="form-control"
                                                    placeholder="Enter  License Number"
                                                    type="text"
                                                    onChange={(e) => setLicenseNo(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>License Expiry</Trans></Label>
                                                <Input
                                                    name="licenseNo"
                                                    value={licenseExpiry}
                                                    className="form-control"
                                                    placeholder="Enter License Expiry"
                                                    type="date"
                                                    onChange={(e) => setLicenseExpiry(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-2 mt-2">
                                                <Label className="form-label"><Trans>Cargo Employee</Trans></Label>
                                                <div className="form-check form-check-end">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={1}
                                                        id="defaultCheck2"
                                                        defaultChecked={cargoEmployee == 1}
                                                        onClick={(e) => handleToggleCheckBox('setCargoEmployee')}
                                                    />

                                                </div>
                                            </div>

                                            <div className="form-group col-md-2 mt-2">
                                                <Label className="form-label"><Trans>Status</Trans></Label>
                                                <div className="form-check form-check-end">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={1}
                                                        id="defaultCheck3"
                                                        defaultChecked={status == 1}
                                                        onClick={(e) => handleToggleCheckBox('setStatus')}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck3"
                                                    >
                                                        <Trans>Active</Trans>
                                                    </label>
                                                </div>
                                            </div>

                                        </Row>
                                        <div className="mt-4">
                                            <Button type="submit" color="danger">
                                                <Trans>Submit</Trans>
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddDriver
