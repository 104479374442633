import UploadImage from "common/uploadImage";
import { ImageBaseUrl } from "helpers/file_upload_helper";
import FormSectionTitle from "pages/Common/FormSectionTitle";
import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { Button, Row, Table } from "reactstrap";
const Description = props => {
    const [description, setDescription] = useState([]);
    useEffect(() => {
        if (props.state) {
            setDescription(props.state.description);
        }
    }, []);
    return (
        <React.Fragment>
            <Row>
                <FormSectionTitle state={{ title: 'Description' }}></FormSectionTitle>
                <Table className="table table-striped  mb-0">
                    <tr style={{ padding: "15", background: "#f5f5f5" }}>
                        <th><Trans>Icon</Trans></th>
                        <th><Trans>Text</Trans></th>
                        <th>#</th>
                    </tr>
                    {
                        description.map((desc, index) => (
                            <tr key={index}>
                                {/* <td><UploadImage state={{ InputLabel: 'Icon', filePath: desc.symbol, type: 'vehicle-categories', InputWidth: '4' }}></UploadImage></td> */}
                                <td width={10}><img style={{ width: "60px" }} src={`${desc.symbol}`}></img></td>
                                <td><textarea className="form-control">
                                    {desc.descr}
                                </textarea></td>
                                <td><Button className="btn btn-sm" color="info">Edit</Button></td>
                            </tr>
                        ))}
                </Table>
            </Row>
        </React.Fragment>
    )
}

export default Description
