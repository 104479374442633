import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form } from "reactstrap";
import { get, put } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import Loader from "common/loader";
import UploadImage from "common/uploadImage";
import { symbol } from "prop-types";
import { SHOW_CATEGORY, UPDATE_CATEGORY } from "helpers/api_endpoints";

//meta title
document.title = "Edit Category";

const EditCategory = () => {

    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [id, setid] = useState('');
    const [name, setname] = useState('');
    const [status, setStatus] = useState(0);
    const [symbol, setSymbol] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        get(`${SHOW_CATEGORY}/${params.id}`)
            .then(response => {
                const category = response.data;
                setid(params.id);
                setname(category.name);
                setStatus(category.status);
                setSymbol(category.symbol);
                setLoading(false);
            })
            .catch(error => {
                navigate('/categories');
            })
    }, []);

    const handleSubmit = () => {
        const imagePath = document.getElementById('imagePath').value;
        put(UPDATE_CATEGORY, { id, name, status, symbol: imagePath })
            .then(response => {
                if (response.code == 200) {
                    navigate('/categories', { state: { successMsg: 'Category Updated SuccessFully' } });
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    const handleToggleCheckBoxStatus = () => {
        if (status) {
            setStatus(0);
        }
        else {
            setStatus(1)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Categories" breadcrumbItem="Edit Category" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        {loading ? <Loader></Loader> :
                                            <Row>
                                                <div className="form-group col-md-6 mt-2">
                                                    <Label className="form-label"><Trans>Name</Trans></Label>
                                                    <Input
                                                        name="username"
                                                        value={name}
                                                        className="form-control"
                                                        placeholder="Enter Name"
                                                        type="text"
                                                        onChange={(e) => setname(e.target.value)}
                                                        required ></Input>
                                                    <Input type="hidden" name="id"></Input>
                                                </div>

                                                <div className="form-group col-md-6 mt-2">
                                                    <Label className="form-label"><Trans>Status</Trans></Label>
                                                    <div className="form-check form-check-end">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value={1}
                                                            id="defaultCheck2"
                                                            checked={status == 1}
                                                            onClick={handleToggleCheckBoxStatus}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck2"
                                                        >
                                                            <Trans>Active</Trans>
                                                        </label>
                                                    </div>
                                                </div>

                                                <UploadImage state={{ InputLabel: 'Image', filePath: symbol, type: 'category' }}></UploadImage>

                                                <div className="mt-4">
                                                    <Button type="submit" color="danger">
                                                        <Trans>Submit</Trans>
                                                    </Button>
                                                </div>
                                            </Row>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditCategory
