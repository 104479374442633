import axios from "axios";

//apply base url for axios
const API_URL = "http://3.20.6.42:3000/api/";
// const API_URL = "http://127.0.0.1:3000/api/";

const axiosApi = axios.create({
  baseURL: API_URL,
});


if (localStorage.getItem('authUser')) {
  setTokenHeader();
}

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, data, config = {}) {console.log({...data});
  return await axiosApi
    .delete(url, {
      data: { ...data }
    }, { ...config })
    .then((response) => response.data);
}

export async function setTokenHeader() {
  const token = JSON.parse(localStorage.getItem('authUser')).token;
  axiosApi.defaults.headers.common["Accept"] = 'application/json';
  return axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
