import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
const FormSectionTitle = props => {
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (props.state) {
            setTitle(props.state.title);
        }
    }, []);

    return (
        <React.Fragment>
            <h4 className="card-title" style={{ border: "1px solid #FE9C19", padding: "10px", borderRadius: "5px" }}><Trans>{title}</Trans></h4>
        </React.Fragment>
    )
}

export default FormSectionTitle
