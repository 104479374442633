import React, { useState } from "react";
import { post } from "helpers/api_helper";
import { useEffect } from "react";
import { ImageBaseUrl } from "helpers/file_upload_helper";
import { Label } from "reactstrap";
import Loader from "./loader";
const UploadImage = props => {

    const [loading, setLoading] = useState(false);
    const [isFullPath, setIsFullPath] = useState(false);
    const [filePathUrl, setFilePathUrl] = useState('');
    const [filePath, setfilePath] = useState('');
    const [InputLabel, setInputLabel] = useState('');
    const [InputWidth, setInputWidth] = useState('4');
    const [type, setType] = useState('');

    useEffect(() => {
        if (props.state) {
            setType(props.state.type);
            setfilePath(props.state.filePath);
            setInputLabel(props.state.InputLabel);
            setInputWidth(props.state.InputWidth);
            if (props.state.isFullPath) {
                setIsFullPath(true);
                setFilePathUrl(props.state.filePath);
            }
            else {
                setFilePathUrl(`${ImageBaseUrl}${props.state.filePath}`);
            }
        }
    }, []);

    const handleFileUpload = (event) => {
        setLoading(true);
        const file = event.target.files[0];
        post('general/upload-image', { file, type }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                const data = response.data;
                if (isFullPath) {
                    setfilePath(`${ImageBaseUrl}${data.path}`);
                    setFilePathUrl(`${ImageBaseUrl}${data.path}`);
                }
                else {
                    setfilePath(data.path);
                }
                setLoading(false);
            })
            .catch((error) => {
            });
    }

    return (
        <div className={`form-group col-md-${InputWidth} mt-2`}>
            <Label className="form-label">{InputLabel}</Label>
            <input className="form-control" type="file" onChange={handleFileUpload} />
            <input type="hidden" name="imagePath" id="imagePath" value={filePath}></input>
            <br></br>
            {loading ? <Loader></Loader> : null}
            {filePath ? (<img src={`${filePathUrl}`} width={220} style={{ padding: "20px", background: "#f5f5f5", borderRadius: "5px" }}></img>) : null}
        </div >
    );
}

export default UploadImage;