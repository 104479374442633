import React from "react"
import { Row } from "reactstrap"

const Loader = () => {
    return (
        <React.Fragment>
            <Row><b colSpan={6} className="text-center" style={{ color: "#FE9C19" }}>Loading...</b></Row>
        </React.Fragment>
    )
}

export default Loader
