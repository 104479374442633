import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form, Alert } from "reactstrap";
import { post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import UploadImage from "common/uploadImage";
import { ADD_SHIPMENT_TYPE } from "helpers/api_endpoints";


//meta title
document.title = "Add Shipment Type";

const AddShipmentType = () => {

    const [status, setStatus] = useState(0);
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [description, setDescription] = useState('');
    const [symbol, setSymbol] = useState('');
    const [image, setImage] = useState('');
    const [errorMsg, seterrorMsg] = useState('');
    const navigate = useNavigate();

    const handleSubmit = () => {
        const imagePath = document.getElementById('imagePath').value;
        post(ADD_SHIPMENT_TYPE, { name, title, subTitle, type, description, status, image: imagePath })
            .then(response => {
                if (response.code == 201) {
                    navigate('/shipment-types', { state: { successMsg: 'Shipment Type Added SuccessFully' } });
                }
            })
            .catch(error => {
                seterrorMsg('Shipment Type Creation Failed');
            })
    }

    const handleToggleCheckBoxStatus = () => {
        if (status == 1) {
            setStatus(0);
        }
        else {
            setStatus(1)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Categories" breadcrumbItem="Add Shipment Type" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Name</Trans></Label>
                                                <Input
                                                    name="name"
                                                    value={name}
                                                    className="form-control"
                                                    placeholder="Enter  Name"
                                                    type="text"
                                                    onChange={(e) => setName(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Title</Trans></Label>
                                                <Input
                                                    name="title"
                                                    value={title}
                                                    className="form-control"
                                                    placeholder="Enter Title"
                                                    type="text"
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Sub Title</Trans></Label>
                                                <Input
                                                    name="title"
                                                    value={subTitle}
                                                    className="form-control"
                                                    placeholder="Enter Sub Title"
                                                    type="text"
                                                    onChange={(e) => setSubTitle(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Type</Trans></Label>
                                                <Input
                                                    name="title"
                                                    value={type}
                                                    className="form-control"
                                                    placeholder="Enter Type"
                                                    type="text"
                                                    onChange={(e) => setType(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Description</Trans></Label>
                                                <Input
                                                    name="title"
                                                    value={description}
                                                    className="form-control"
                                                    placeholder="Enter Description"
                                                    type="text"
                                                    onChange={(e) => setDescription(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-3 mt-2">
                                                <Label className="form-label"><Trans>Status</Trans></Label>
                                                <div className="form-check form-check-end">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={1}
                                                        id="defaultCheck2"
                                                        defaultChecked={status}
                                                        onClick={handleToggleCheckBoxStatus}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck2"
                                                    >
                                                        <Trans>Active</Trans>
                                                    </label>
                                                </div>
                                            </div>
                                            <UploadImage state={{ InputLabel: 'Image', filePath: image, type: 'shipmentTypes', InputWidth: '6' }}></UploadImage>

                                        </Row>
                                        <div className="mt-4">
                                            <Button type="submit" color="danger">
                                                <Trans>Submit</Trans>
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddShipmentType
