import React, { useState } from "react";
import { post } from "helpers/api_helper";
import { useEffect } from "react";
import { ImageBaseUrl } from "helpers/file_upload_helper";
import { Label, Row } from "reactstrap";
import Loader from "./loader";
const UploadImages = props => {

    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [InputLabel, setInputLabel] = useState('');
    const [InputWidth, setInputWidth] = useState('4');
    const [type, setType] = useState('');

    useEffect(() => {
        if (props.state) {
            setType(props.state.type);
            setFiles(props.state.files);
            setInputLabel(props.state.InputLabel);
            setInputWidth(props.state.InputWidth);
        }
    }, []);

    const handleFileUpload = (event) => {
        setLoading(true);
        const imagefiles = event.target.files;
        console.log(imagefiles);
        imagefiles.map((imgfile) =>
            post('general/upload-image', { imgfile, type }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    const data = response.data;
                    setfilePath(data.path);
                    setLoading(false);
                })
        )
        // imagefiles.forEach(imagefile => {
        //     post('general/upload-image', { imagefile, type }, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         }
        //     })
        //         .then((response) => {
        //             const data = response.data;
        //             setfilePath(data.path);
        //             setLoading(false);
        //         })
        //         .catch((error) => {
        //         });
        // });
    }

    return (
        <div className={`form-group col-md-${InputWidth} mt-2`}>
            <Label className="form-label">{InputLabel}</Label>
            <input className="form-control" type="file" onChange={handleFileUpload} multiple />
            <input type="hidden" name="imagePaths" id="imagePaths" value={files}></input>
            <br></br>
            {loading ? <Loader></Loader> : null}
            <Row>
                {files.map((file, index) => (<img style={{ width: "100px", background: "#f5f5f5", padding: "5px", marginRight: "5px" }} key={index} src={`${file.path}`}></img>))}
            </Row>
        </div >
    );
}

export default UploadImages;