import React, { useEffect, useState } from "react";
import { post } from "../../helpers/api_helper";
//redux
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap";
import Loader from "common/loader";
import { Trans } from "react-i18next";
import Breadcrumb from "components/Common/Breadcrumb";
import { UPDATE_FAQ } from "helpers/api_endpoints";

const UpdateFaq = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState('');
    const [name, setTitle] = useState('');
    const [value, setDescription] = useState('');

    useEffect(() => {
        const faq = location.state;
        setId(faq.id);
        setTitle(faq.name);
        setDescription(faq.value);
        setLoading(false);
    }, []);

    const handleSubmit = () => {
        post(UPDATE_FAQ, { id, name, value })
            .then(response => {
                if (response.code == 200) {
                    navigate('/faqs', { state: { successMsg: 'Updated SuccessFully' } });
                }
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="FAQs" breadcrumbItem="Edit FAQs" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        {loading ? <Loader></Loader> :
                                            <Row>
                                                <div className="form-group col-md-12 mt-2">
                                                    <Label className="form-label"><Trans>Question</Trans></Label>
                                                    <Input
                                                        name="title"
                                                        value={name}
                                                        className="form-control"
                                                        placeholder="Enter User Name"
                                                        type="text"
                                                        onChange={(e) => setTitle(e.target.value)}
                                                        required ></Input>
                                                    <Input type="hidden" name="id"></Input>
                                                </div>

                                                <div className="form-group col-md-12 mt-2">
                                                    <Label className="form-label"><Trans>Answer</Trans></Label>
                                                    <textarea
                                                        name="description"
                                                        value={value}
                                                        rows={5}
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        type="text"
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        required ></textarea>
                                                </div>


                                                <div className="text-center  mt-4">
                                                    <Button type="submit" color="danger">
                                                        <Trans>Submit</Trans>
                                                    </Button>
                                                </div>
                                            </Row>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
};

export default UpdateFaq;