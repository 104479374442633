import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input, Label, Button, Form, Alert } from "reactstrap";
import { post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { Link, Route, useNavigate } from "react-router-dom";
import { ADD_ADDRESS } from "helpers/api_endpoints";


//meta title
document.title = "Add Address";

const AddAddress = () => {

    const [type, settype] = useState(1);
    const [status, setstatus] = useState(1);
    const [street, setstreet] = useState('');
    const [phone, setphone] = useState('');
    const [title, settitle] = useState('');
    const [countryId, setcountry] = useState(183);
    const [emirateId, setemirate] = useState(1);
    const [building, setbuilding] = useState('');
    const [flatNo, setflatno] = useState('');
    const [longitude, setlongitude] = useState('24.477559');
    const [latitude, setlatitude] = useState('54.385204');
    const [errorMsg, seterrorMsg] = useState('');
    const navigate = useNavigate();

    const handleSubmit = () => {
        post(ADD_ADDRESS, { type, status, street, phone, title, countryId, emirateId, building, flatNo, longitude, latitude })
            .then(response => {
                // console.log(response)
                if (response.code == 201) {
                    navigate('/addresses', { state: { successMsg: 'Added SuccessFully' } });
                }
            })
            .catch(error => {
                seterrorMsg('Creation Failed');
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Addresses" breadcrumbItem="Add" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}
                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Title</Trans></Label>
                                                <Input
                                                    name="title"
                                                    // value={name}
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    type="text"
                                                    onChange={(e) => settitle(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Street</Trans></Label>
                                                <Input
                                                    name="street"
                                                    // value={name}
                                                    className="form-control"
                                                    placeholder="Enter Street"
                                                    type="text"
                                                    onChange={(e) => setstreet(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Building</Trans></Label>
                                                <Input
                                                    name="building"
                                                    // value={name}
                                                    className="form-control"
                                                    placeholder="Enter building no"
                                                    type="text"
                                                    onChange={(e) => setbuilding(e.target.value)} required
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Phone</Trans></Label>
                                                <Input
                                                    name="phone"
                                                    // value={name}
                                                    className="form-control"
                                                    placeholder="Enter Email"
                                                    type="password"
                                                    onChange={(e) => setphone(e.target.value)} required
                                                ></Input>
                                            </div>
                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Flat Number</Trans></Label>
                                                <Input
                                                    name="flatNo"
                                                    // value={name}
                                                    className="form-control"
                                                    placeholder="Enter Flat number"
                                                    type="text"
                                                    onChange={(e) => setflatno(e.target.value)} required
                                                ></Input>
                                            </div>
                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label" for="typefrom"><Trans>Type : Pick up address</Trans></Label>
                                                <Input
                                                    id="typefrom"
                                                    name="type"
                                                    className="form-control"
                                                    type="radio"
                                                    value="1"
                                                    onChange={(e) => settype(e.target.value)} required
                                                ></Input>
                                                <Label className="form-label" for="typeto"><Trans>Type : Delivery address</Trans></Label>
                                                <Input
                                                    id="typeto"
                                                    name="type"
                                                    className="form-control"
                                                    type="radio"
                                                    value="2"
                                                    onChange={(e) => settype(e.target.value)} required
                                                ></Input>
                                            </div>
                                        </Row>
                                        <div className="text-center  mt-4">
                                            <Button type="submit" color="danger">
                                                <Trans>Submit</Trans>
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddAddress
