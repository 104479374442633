import usFlag from "../assets/images/flags/us.jpg"
import aeFlag from "../assets/images/flags/uae.png"

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  ar: {
    label: "Arabic",
    flag: aeFlag,
  },
}

export default languages
