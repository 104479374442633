import React, { useEffect, useState } from "react";
import { put, get } from "../../../helpers/api_helper";
//redux
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap";
import Loader from "common/loader";
import { Trans } from "react-i18next";
import Breadcrumb from "components/Common/Breadcrumb";
import SECTION_TYPE from "../common/SectionEnumType";
import { SCREENS_LIST, UPDATE_SCREEN_SECTION } from "helpers/api_endpoints";

const EditSection = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState('');
    const [screens, setScreens] = useState([]);
    const [status, setStatus] = useState(0);
    const [order, setOrder] = useState('');
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [api, setApi] = useState('');
    const [screenId, setScreenId] = useState('');

    useEffect(() => {
        const section = location.state;
        getScreens();
        setId(section.id);
        setOrder(section.order);
        setScreenId(section.screenId);
        setTitle(section.title);
        setType(section.type);
        setApi(section.api);
        setStatus(section.status);
        setLoading(false);
    }, []);

    const handleSubmit = () => {
        put(UPDATE_SCREEN_SECTION, { id, screenId, title, status, type, api, order })
            .then(response => {
                if (response.code == 200) {
                    navigate('/sections', { state: { successMsg: 'Updated SuccessFully' } });
                }
            })
    }

    const handleToggleCheckBoxStatus = () => {
        if (status) {
            setStatus(0);
        }
        else {
            setStatus(1)
        }
    }

    const getScreens = () => {
        get(SCREENS_LIST)
            .then(response => {
                const data = response.data;
                setScreens(data);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    navigate('/logout');
                }
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Screens" breadcrumbItem="Edit Screen" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        {loading ? <Loader></Loader> :
                                            <Row>
                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Screen</Trans></Label>
                                                    <select className="form-control" onChange={(e) => setScreenId(e.target.value)}>
                                                        <option value=''><Trans>Select Screen</Trans></option>
                                                        {
                                                            screens.map((screen, index) => (
                                                                <option key={index} value={screen.id} selected={screen.id == screenId}>{screen.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Title</Trans></Label>
                                                    <Input
                                                        name="title"
                                                        value={title}
                                                        className="form-control"
                                                        placeholder="Enter Title"
                                                        type="text"
                                                        onChange={(e) => setTitle(e.target.value)}
                                                        required ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Type</Trans></Label>
                                                    <select className="form-control" onChange={(e) => setType(e.target.value)}>
                                                        <option value=''><Trans>Select Type</Trans></option>
                                                        {
                                                            SECTION_TYPE.map((section_type, index) => (
                                                                <option key={index} value={section_type} selected={section_type == type}>{section_type}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Api End Point</Trans></Label>
                                                    <Input
                                                        name="api"
                                                        value={api}
                                                        className="form-control"
                                                        placeholder="Enter Api End Point"
                                                        type="text"
                                                        onChange={(e) => setApi(e.target.value)}
                                                        required ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Order</Trans></Label>
                                                    <Input
                                                        name="order"
                                                        value={order}
                                                        className="form-control"
                                                        placeholder="Enter Order"
                                                        type="number"
                                                        onChange={(e) => setOrder(e.target.value)}
                                                        required ></Input>
                                                </div>

                                                <div className="form-group col-md-3 mt-2">
                                                    <Label className="form-label"><Trans>Status</Trans></Label>
                                                    <div className="form-check form-check-end">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value={1}
                                                            id="defaultCheck2"
                                                            defaultChecked={status == 1}
                                                            onClick={handleToggleCheckBoxStatus}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck2"
                                                        >
                                                            <Trans>Active</Trans>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="text-center  mt-4">
                                                    <Button type="submit" color="danger">
                                                        <Trans>Submit</Trans>
                                                    </Button>
                                                </div>
                                            </Row>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditSection;