import React, { useEffect } from "react";
import withRouter from "components/Common/withRouter";
import { del } from "../../helpers/api_helper";
//redux
import { useNavigate, useParams } from "react-router-dom";
import { REMOVE_VEHICLE } from "helpers/api_endpoints";

const RemoveVehicle = () => {
    const history = useNavigate();
    const params = useParams();
    const id = params.id;
    const navigate = useNavigate();

    useEffect(() => {
        del(REMOVE_VEHICLE, { id })
            .then(response => {
                navigate('/vehicles', { state: { successMsg: 'deleted SuccessFully' } });
            })
            .catch(error => {
                console.log(error);
            })
    });

    return <React.Fragment>Removed</React.Fragment>;
};

export default withRouter(RemoveVehicle);