import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form } from "reactstrap";
import { get, put } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import Loader from "common/loader";
import UploadImage from "common/uploadImage";
import { SHOW_ITEM, UPDATE_ITEM } from "helpers/api_endpoints";

//meta title
document.title = "Edit Item";

const EditItem = () => {

    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [id, setid] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [weight, setWeight] = useState('');
    const [symbol, setSymbol] = useState('');
    const [attributes, setAttributes] = useState('');
    const [dimensions, setDimensions] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState(0);
    const [image, setImage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        get(`${SHOW_ITEM}/${params.id}`)
            .then(response => {
                const item = response.data;
                setid(params.id);
                setName(item.name);
                setType(item.type);
                setWeight(item.weight);
                setStatus(item.status);
                setDimensions(item.dimensions);
                setDescription(item.description);
                setSymbol(item.symbol);
                setAttributes(item.attributes);
                setLoading(false);
            })
            .catch(error => {
                navigate('/items');
            })
    }, []);

    const handleSubmit = () => {
        const imagePath = document.getElementById('imagePath').value;
        put(UPDATE_ITEM, { id, name, type, symbol, attributes, weight, dimensions, description, status, image: imagePath })
            .then(response => {
                if (response.code == 200) {
                    navigate('/items', { state: { successMsg: 'Item Updated SuccessFully' } });
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    const handleToggleCheckBoxStatus = () => {
        if (status) {
            setStatus(0);
        }
        else {
            setStatus(1)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Items" breadcrumbItem="Edit Item" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        {loading ? <Loader></Loader> :
                                            <Row>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Type</Trans></Label>
                                                    <Input
                                                        name="type"
                                                        value={type}
                                                        className="form-control"
                                                        placeholder="Enter  Type"
                                                        type="text"
                                                        onChange={(e) => setType(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Name</Trans></Label>
                                                    <Input
                                                        name="name"
                                                        value={name}
                                                        className="form-control"
                                                        placeholder="Enter  Name"
                                                        type="text"
                                                        onChange={(e) => setName(e.target.value)}
                                                        required ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Symbol</Trans></Label>
                                                    <Input
                                                        name="symbol"
                                                        value={symbol}
                                                        className="form-control"
                                                        placeholder="Enter Symbol"
                                                        type="text"
                                                        onChange={(e) => setSymbol(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Attributes</Trans></Label>
                                                    <Input
                                                        name="attributes"
                                                        value={attributes}
                                                        className="form-control"
                                                        placeholder="Enter Attributes"
                                                        type="text"
                                                        onChange={(e) => setAttributes(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Weight</Trans></Label>
                                                    <Input
                                                        name="weight"
                                                        value={weight}
                                                        className="form-control"
                                                        placeholder="Enter Weight"
                                                        type="text"
                                                        onChange={(e) => setWeight(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Dimensions</Trans></Label>
                                                    <Input
                                                        name="dimensions"
                                                        value={dimensions}
                                                        className="form-control"
                                                        placeholder="Enter Dimensions"
                                                        type="text"
                                                        onChange={(e) => setDimensions(e.target.value)}
                                                    ></Input>
                                                </div>

                                                <div className="form-group col-md-4 mt-2">
                                                    <Label className="form-label"><Trans>Description</Trans></Label>
                                                    <textarea
                                                        name="description"
                                                        value={description}
                                                        className="form-control"
                                                        placeholder="Enter Description"
                                                        type="text"
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    ></textarea>
                                                </div>

                                                <UploadImage state={{ InputLabel: 'Image', filePath: image, type: 'items', InputWidth: '4' }}></UploadImage>

                                                <div className="form-group col-md-2 mt-2">
                                                    <Label className="form-label"><Trans>Status</Trans></Label>
                                                    <div className="form-check form-check-end">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value={1}
                                                            id="defaultCheck2"
                                                            defaultChecked={status == 1}
                                                            onClick={handleToggleCheckBoxStatus}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck2"
                                                        >
                                                            <Trans>Active</Trans>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="mt-4">
                                                    <Button type="submit" color="danger">
                                                        <Trans>Submit</Trans>
                                                    </Button>
                                                </div>
                                            </Row>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditItem
