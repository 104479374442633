import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, Alert, Badge } from "reactstrap"
import { get } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "common/loader";
import { SCREENS_LIST } from "helpers/api_endpoints";
//meta title
document.title = "Screens List";
const ScreensList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [screens, setScreens] = useState([]);
    const [successMsg, setsuccessMsg] = useState('');

    useEffect(() => {
        successMessage();
        get(SCREENS_LIST)
            .then(response => {
                const data = response.data;
                setScreens(data);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    navigate('/logout');
                }
            })
    }, []);


    const successMessage = () => {
        if (location.state) {
            setsuccessMsg(location.state.successMsg);
            setTimeout(() => {
                setsuccessMsg('');
            }, 4000)
        }
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Screens" />
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <br></br>
                                {successMsg ? <Alert style={{ margin: "25px" }} color="success">{successMsg}</Alert> : null}
                                <Link to="/screens/add" >
                                    <button style={{ float: "right", marginRight: "20px" }} className="btn btn-sm btn-primary"><i className="dripicons-plus"></i> &nbsp;<Trans>Create</Trans></button>
                                </Link>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th><Trans>Id</Trans></th>
                                                    <th><Trans>Name</Trans></th>
                                                    <th><Trans>Title</Trans></th>
                                                    <th><Trans>Current Version</Trans></th>
                                                    <th><Trans>Status</Trans></th>
                                                    <th>#</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? <tr><th colSpan={6} className="text-center"><Loader></Loader></th></tr> : null}
                                                {
                                                    screens.map((screen, index) => (
                                                        <tr key={index}>
                                                            <td>{screen.id}</td>
                                                            <td>{screen.name}</td>
                                                            <td>{screen.title}</td>
                                                            <td>{screen.currentVersion}</td>
                                                            <td>{(screen.status == 1) ? <Badge className="bg-success"><Trans>Active</Trans></Badge> : <Badge className="bg-danger"><Trans>Non Active</Trans></Badge>}</td>
                                                            <td><Link to={{ pathname: `/screens/edit/${screen.id}` }} state={screen}><button className="btn btn-sm btn-info"><i className="dripicons-pencil"></i> &nbsp;<Trans>Edit</Trans></button></Link> &nbsp;
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ScreensList
