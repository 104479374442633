import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { get } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import Loader from "common/loader";
import { SHOW_TICKET } from "helpers/api_endpoints";



//meta title
document.title = "Ticket Details";

const TicketDetails = () => {
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [ticket, setTicket] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        get(`${SHOW_TICKET}/${params.id}`)
            .then(response => {
                const ticket = response.data;
                setTicket(ticket);
                setLoading(false);
            })
            .catch(error => {
                navigate('/tickets');
            })
    }, []);


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Tickets" breadcrumbItem="Ticket Details" />
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    {loading ? <Loader></Loader> :
                                        <Row>
                                            <div className="table-responsive">
                                                <table className="table table-striped table-bordered mt-0">
                                                    <tbody>
                                                        <tr>
                                                            <th><Trans>ID</Trans></th><td colSpan={2}>{ticket.id}</td>
                                                        </tr>
                                                        {ticket.User ? <tr>
                                                            <th><Trans>User Details</Trans></th>
                                                            <td>{ticket.User.name}</td>
                                                            <td>{ticket.User.email}</td>
                                                        </tr> : null}
                                                        <tr>
                                                            <th><Trans>Type</Trans></th>
                                                            <td colSpan={2}>{ticket.ticketType}</td>
                                                        </tr>
                                                        <tr>
                                                            <th><Trans>Title</Trans></th>
                                                            <td colSpan={2}><input className="form-control" value={ticket.title} readOnly></input></td>
                                                        </tr>
                                                        <tr>
                                                            <th><Trans>Message</Trans></th>
                                                            <td colSpan={2}><textarea className="form-control" rows={8} value={ticket.description} readOnly></textarea></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TicketDetails
