import React, { useState } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form, Alert, CardHeader } from "reactstrap";
import { post } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import UploadImage from "common/uploadImage";
import { ADD_VEHICLE } from "helpers/api_endpoints";

//meta title
document.title = "Add Vehicle";

const AddVehicle = () => {

    const [name, setname] = useState('');
    const [modelId, setModelId] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [modelName, setModelName] = useState('');
    const [manufactureYear, setManufactureYear] = useState('');
    const [color, setColor] = useState(0);
    const [chasisNo, setChasisNo] = useState('');
    const [engineNo, setEngineNo] = useState('');
    const [conditioned, setConditioned] = useState(1);
    const [secured, setSecured] = useState(1);
    const [pilotNo, setPilotNo] = useState('');
    const [passangerNo, setPassangerNo] = useState('');
    const [trunkSize, setTrunkSize] = useState('');
    const [height, setHeight] = useState('');
    const [model, setModel] = useState('');
    const [plateNo, setPlateNo] = useState('');
    const [registrationSource, setRegistrationSource] = useState('');
    const [details, setDetails] = useState('');
    const [registrationExpiry, setRegistrationExpiry] = useState('');
    const [capacity, setCapacity] = useState('');
    const [symbol, setSymbol] = useState('');
    const [status, setStatus] = useState(0);
    const [errorMsg, seterrorMsg] = useState('');
    const navigate = useNavigate();

    const handleSubmit = () => {
        const imagePath = document.getElementById('imagePath').value;
        const config = {
            name,
            modelId: parseInt(modelId),
            categoryId: parseInt(categoryId),
            modelName,
            manufactureYear,
            color,
            chasisNo,
            engineNo,
            conditioned,
            secured,
            pilotNo: parseInt(pilotNo),
            passangerNo: parseInt(passangerNo),
            trunkSize,
            height,
            model,
            plateNo,
            details,
            registrationExpiry,
            registrationSource,
            capacity,
            symbol: imagePath
        };

        post(ADD_VEHICLE, config)
            .then(response => {
                if (response.code == 201) {
                    navigate('/vehicles', { state: { successMsg: 'Vehicle Added SuccessFully' } });
                }
            })
            .catch(error => {
                seterrorMsg('Vehicle Creation Failed');
            })
    }

    const handleToggleCheckBoxStatus = () => {
        if (status) {
            setStatus(0);
        }
        else {
            setStatus(1)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Vehicles" breadcrumbItem="Add Vehicle" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={6}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title"><Trans>General Information</Trans></h4>
                                        <Row>
                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Name</Trans></Label>
                                                <Input
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Enter  Name"
                                                    type="text"
                                                    onChange={(e) => setname(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Model Id</Trans></Label>
                                                <Input
                                                    name="modelId"
                                                    className="form-control"
                                                    placeholder="Enter Model Id"
                                                    type="number"
                                                    onChange={(e) => setModelId(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Category Id</Trans></Label>
                                                <Input
                                                    name="categoryId"
                                                    className="form-control"
                                                    placeholder="Enter Category Id"
                                                    type="number"
                                                    onChange={(e) => setCategoryId(e.target.value)}
                                                ></Input>
                                            </div>


                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Model Name</Trans></Label>
                                                <Input
                                                    name="modelName"
                                                    className="form-control"
                                                    placeholder="Enter Model Name"
                                                    type="text"
                                                    onChange={(e) => setModelName(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Height</Trans></Label>
                                                <Input
                                                    name="height"
                                                    className="form-control"
                                                    placeholder="Enter Height"
                                                    type="text"
                                                    onChange={(e) => setHeight(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Model</Trans></Label>
                                                <Input
                                                    name="model"
                                                    className="form-control"
                                                    placeholder="Enter Model"
                                                    type="text"
                                                    onChange={(e) => setModel(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Capacity</Trans></Label>
                                                <Input
                                                    name="capacity"
                                                    className="form-control"
                                                    placeholder="Enter Capacity"
                                                    type="text"
                                                    onChange={(e) => setCapacity(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Manufacture Year</Trans></Label>
                                                <Input
                                                    name="manufactureYear"
                                                    className="form-control"
                                                    placeholder="Enter Manufacture Year"
                                                    type="text"
                                                    onChange={(e) => setManufactureYear(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Status</Trans></Label>
                                                <div className="form-check form-check-end">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={1}
                                                        id="defaultCheck2"
                                                        checked={status}
                                                        onClick={handleToggleCheckBoxStatus}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck2"
                                                    >
                                                        <Trans>Active</Trans>
                                                    </label>
                                                </div>
                                            </div>


                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xl={6}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title"><Trans>Vehicle Information</Trans></h4>
                                        <Row>
                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Chasis Number</Trans></Label>
                                                <Input
                                                    name="chasisNo"
                                                    className="form-control"
                                                    placeholder="Enter Chasis Number"
                                                    type="text"
                                                    onChange={(e) => setChasisNo(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Engine Number</Trans></Label>
                                                <Input
                                                    name="engineNo"
                                                    className="form-control"
                                                    placeholder="Enter Engine Number"
                                                    type="text"
                                                    onChange={(e) => setEngineNo(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Color</Trans></Label>
                                                <Input
                                                    name="color"
                                                    className="form-control"
                                                    placeholder="Enter Color"
                                                    type="text"
                                                    onChange={(e) => setColor(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Pilot Number</Trans></Label>
                                                <Input
                                                    name="pilotNo"
                                                    className="form-control"
                                                    placeholder="Enter Pilot Number"
                                                    type="number"
                                                    onChange={(e) => setPilotNo(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Passenger Number</Trans></Label>
                                                <Input
                                                    name="passangerNo"
                                                    className="form-control"
                                                    placeholder="Enter Passenger Number"
                                                    type="number"
                                                    onChange={(e) => setPassangerNo(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Trunk Size</Trans></Label>
                                                <Input
                                                    name="trunkSize"
                                                    className="form-control"
                                                    placeholder="Enter Trunk Size"
                                                    type="text"
                                                    onChange={(e) => setTrunkSize(e.target.value)}
                                                ></Input>
                                            </div>



                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Plate Number</Trans></Label>
                                                <Input
                                                    name="plateNo"
                                                    className="form-control"
                                                    placeholder="Enter Plate Number"
                                                    type="text"
                                                    onChange={(e) => setPlateNo(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Registration Source</Trans></Label>
                                                <Input
                                                    name="registrationSource"
                                                    className="form-control"
                                                    placeholder="Enter Registration Source"
                                                    type="text"
                                                    onChange={(e) => setRegistrationSource(e.target.value)}
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-4 mt-2">
                                                <Label className="form-label"><Trans>Registration Expiry</Trans></Label>
                                                <Input
                                                    name="registrationExpiry"
                                                    className="form-control"
                                                    placeholder="Enter Registration Source"
                                                    type="date"
                                                    onChange={(e) => setRegistrationExpiry(e.target.value)}
                                                ></Input>
                                            </div>


                                            <div className="form-group col-md-3 mt-2">
                                                <Label className="form-label"><Trans>Conditioned</Trans></Label>
                                                <div className="form-check form-check-end">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={1}
                                                        id="defaultCheck2"
                                                        checked={conditioned}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck2"
                                                    >
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-3 mt-2">
                                                <Label className="form-label"><Trans>Secured</Trans></Label>
                                                <div className="form-check form-check-end">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={1}
                                                        id="defaultCheck2"
                                                        checked={secured}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck2"
                                                    >
                                                    </label>
                                                </div>
                                            </div>

                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xl={6}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title"><Trans>Media Section</Trans></h4>
                                        <Row>
                                            <UploadImage state={{ InputLabel: 'Image', filePath: symbol, type: 'vehicles', InputWidth: '12' }}></UploadImage>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <div className="mt-4">
                                <Button type="submit" color="danger">
                                    <Trans>Submit</Trans>
                                </Button>
                            </div>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddVehicle
