import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, Alert, Badge } from "reactstrap"
import { get } from "../../../helpers/api_helper";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "common/loader";
import { SCREEN_SECTION_LIST } from "helpers/api_endpoints";
//meta title
document.title = "Screens Sections List";
const SectionsList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [sections, setSections] = useState([]);
    const [successMsg, setsuccessMsg] = useState('');

    useEffect(() => {
        successMessage();
        get(SCREEN_SECTION_LIST)
            .then(response => {
                const data = response.data;
                setSections(data);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    navigate('/logout');
                }
            })
    }, []);


    const successMessage = () => {
        if (location.state) {
            setsuccessMsg(location.state.successMsg);
            setTimeout(() => {
                setsuccessMsg('');
            }, 4000)
        }
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Screen Sections" />
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <br></br>
                                {successMsg ? <Alert style={{ margin: "25px" }} color="success">{successMsg}</Alert> : null}
                                <Link to="/sections/add" >
                                    <button style={{ float: "right", marginRight: "20px" }} className="btn btn-sm btn-primary"><i className="dripicons-plus"></i> &nbsp;<Trans>Create</Trans></button>
                                </Link>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th><Trans>Id</Trans></th>
                                                    <th><Trans>Screen</Trans></th>
                                                    <th><Trans>Type</Trans></th>
                                                    <th><Trans>Title</Trans></th>
                                                    <th><Trans>Api End Point</Trans></th>
                                                    <th><Trans>Order</Trans></th>
                                                    <th><Trans>Status</Trans></th>
                                                    <th>#</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? <tr><th colSpan={8} className="text-center"><Loader></Loader></th></tr> : null}
                                                {
                                                    sections.map((section, index) => (
                                                        <tr key={index}>
                                                            <td>{section.id}</td>
                                                            <td><Badge className="bg-primary">{section.Screen.name}</Badge></td>
                                                            <td><Badge className="bg-info">{section.type}</Badge></td>
                                                            <td>{section.title}</td>
                                                            <td>{section.api}</td>
                                                            <td>{section.order}</td>
                                                            <td>{(section.status == 1) ? <Badge className="bg-success"><Trans>Active</Trans></Badge> : <Badge className="bg-danger"><Trans>Non Active</Trans></Badge>}</td>
                                                            <td><Link to={{ pathname: `/sections/edit/${section.id}` }} state={section}><button className="btn btn-sm btn-info"><i className="dripicons-pencil"></i> &nbsp;<Trans>Edit</Trans></button></Link> &nbsp;
                                                                <Link to={{ pathname: `/sections/remove/${section.id}` }} ><button className="btn btn-sm btn-danger"><i className="dripicons-trash"></i> &nbsp;<Trans>Remove</Trans></button></Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SectionsList
