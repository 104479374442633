import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Input, Label, Button, Form, Alert } from "reactstrap";
import { get, put } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Trans } from 'react-i18next';
import Loader from "common/loader";
import { GENERAL_STARTUP, UPDATE_STARTUP } from "helpers/api_endpoints";


//meta title
document.title = "Application Setup";

const ApplicationSetup = () => {
    const [loading, setLoading] = useState(true);
    const [id, setid] = useState('');
    const [androidMinVersion, setAndroidMinVersion] = useState('');
    const [iosMinVersion, setIosMinVersion] = useState('');
    const [currentVersion, setCurrentVersion] = useState('');
    const [testVersion, setTestVersion] = useState('');
    const [successMsg, setsuccessMsg] = useState('');

    useEffect(() => {
        get(GENERAL_STARTUP)
            .then(response => {
                const data = response.data;
                setid(data.id);
                setAndroidMinVersion(data.androidMinVersion);
                setIosMinVersion(data.iosMinVersion);
                setCurrentVersion(data.currentVersion);
                setTestVersion(data.testVersion);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const handleSubmit = () => {
        put(UPDATE_STARTUP, { id, androidMinVersion, iosMinVersion, currentVersion, testVersion })
            .then(response => {
                if (response.code == 200) {
                    setsuccessMsg("Updated Successfully");
                    setTimeout(() => {
                        setsuccessMsg('');
                    }, 3000)
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Application" breadcrumbItem="Application Setup" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        {successMsg ? <Alert style={{ margin: "5px" }} color="success">{successMsg}</Alert> : null}
                                        {loading ? <Loader></Loader> :
                                            <Row>
                                                <div className="form-group col-md-6 mt-2">
                                                    <Label className="form-label"><Trans>Android Minimum Version</Trans></Label>
                                                    <Input
                                                        name="androidMinVersion"
                                                        value={androidMinVersion}
                                                        className="form-control"
                                                        placeholder="Enter Android Minimum"
                                                        type="text"
                                                        onChange={(e) => setAndroidMinVersion(e.target.value)}
                                                    ></Input>
                                                </div>
                                                <div className="form-group col-md-6 mt-2">
                                                    <Label className="form-label"><Trans>IOS Minimum Version</Trans></Label>
                                                    <Input
                                                        name="iosMinVersion"
                                                        value={iosMinVersion}
                                                        className="form-control"
                                                        placeholder="Enter Ios Minimum"
                                                        type="text"
                                                        onChange={(e) => setIosMinVersion(e.target.value)}
                                                    ></Input>
                                                </div>
                                                <div className="form-group col-md-6 mt-2">
                                                    <Label className="form-label"><Trans>Current Version</Trans></Label>
                                                    <Input
                                                        name="currentVersion"
                                                        value={currentVersion}
                                                        className="form-control"
                                                        placeholder="Enter Current Version"
                                                        type="text"
                                                        onChange={(e) => setCurrentVersion(e.target.value)}
                                                    ></Input>
                                                </div>
                                                <div className="form-group col-md-6 mt-2">
                                                    <Label className="form-label"><Trans>Test Version</Trans></Label>
                                                    <Input
                                                        name="testVersion"
                                                        value={testVersion}
                                                        className="form-control"
                                                        placeholder="Enter Test Version"
                                                        type="text"
                                                        onChange={(e) => setTestVersion(e.target.value)}
                                                    ></Input>
                                                </div>
                                                <div className="text-center  mt-4">
                                                    <Button type="submit" color="danger">
                                                        <Trans>Submit</Trans>
                                                    </Button>
                                                </div>
                                            </Row>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ApplicationSetup
