import React, { useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input, Label, Button, Form } from "reactstrap";
import { get, put } from "../../helpers/api_helper";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { error } from "toastr";
import { Trans } from 'react-i18next';
import { Link, Route, useNavigate, useParams } from "react-router-dom";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "common/loader";
import { SHOW_ADDRESS, UPDATE_ADDRESS } from "helpers/api_endpoints";


//meta title
document.title = "Edit Address";

const EditAddress = () => {
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [id, setid] = useState('');
    const [type, settype] = useState(1);
    const [status, setstatus] = useState(1);
    const [street, setstreet] = useState('');
    const [phone, setphone] = useState('');
    const [title, settitle] = useState('');
    const [countryId, setcountry] = useState(183);
    const [emirateId, setemirate] = useState(1);
    const [building, setbuilding] = useState('');
    const [flatNo, setflatno] = useState('');
    const [longitude, setlongitude] = useState('24.477559');
    const [latitude, setlatitude] = useState('54.385204');
    const navigate = useNavigate();

    useEffect(() => {
        get(`${SHOW_ADDRESS}/${params.id}`)
            .then(response => {
                const address = response.data;
                setid(params.id);
                settype(address.type);
                setstatus(address.status);
                setstreet(address.street);
                setphone(address.phone);
                settitle(address.title);
                setcountry(address.countryId);
                setemirate(address.emirateId);
                setbuilding(address.building);
                setflatno(address.flatNo);
                setlongitude(address.longitude);
                setlatitude(address.latitude);
                setLoading(false);
            })
            .catch(error => {
                navigate('/address');
            })
    }, []);

    const handleSubmit = () => {
        put(UPDATE_ADDRESS, { id, type, status, street, phone, title, countryId, emirateId, building, flatNo, longitude, latitude })
            .then(response => {
                // console.log(response)
                if (response.code == 200) {
                    navigate('/address', { state: { successMsg: 'User Updated SuccessFully' } });
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Address" breadcrumbItem="Edit Address" />
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        {loading ? <Loader></Loader> :
                                            <Row>
                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Title</Trans></Label>
                                                <Input
                                                    name="title"
                                                    value={title}
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    type="text"
                                                    onChange={(e) => settitle(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Street</Trans></Label>
                                                <Input
                                                    name="street"
                                                    value={street}
                                                    className="form-control"
                                                    placeholder="Enter Street"
                                                    type="text"
                                                    onChange={(e) => setstreet(e.target.value)}
                                                    required ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Building</Trans></Label>
                                                <Input
                                                    name="building"
                                                    value={building}
                                                    className="form-control"
                                                    placeholder="Enter building no"
                                                    type="text"
                                                    onChange={(e) => setbuilding(e.target.value)} required
                                                ></Input>
                                            </div>

                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Phone</Trans></Label>
                                                <Input
                                                    name="phone"
                                                    value={phone}
                                                    className="form-control"
                                                    placeholder="Enter Email"
                                                    type="password"
                                                    onChange={(e) => setphone(e.target.value)} required
                                                ></Input>
                                            </div>
                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label"><Trans>Flat Number</Trans></Label>
                                                <Input
                                                    name="flatNo"
                                                    value={flatNo}
                                                    className="form-control"
                                                    placeholder="Enter Flat number"
                                                    type="text"
                                                    onChange={(e) => setflatno(e.target.value)} required
                                                ></Input>
                                            </div>
                                            <div className="form-group col-md-6 mt-2">
                                                <Label className="form-label" for="typefrom"><Trans>Type : Pick up address</Trans></Label>
                                                <Input
                                                    id="typefrom"
                                                    name="type"
                                                    className="form-control"
                                                    type="radio"
                                                    value="1"
                                                    onChange={(e) => settype(e.target.value)} required
                                                ></Input>
                                                <Label className="form-label" for="typeto"><Trans>Type : Delivery address</Trans></Label>
                                                <Input
                                                    id="typeto"
                                                    name="type"
                                                    className="form-control"
                                                    type="radio"
                                                    value="2"
                                                    onChange={(e) => settype(e.target.value)} required
                                                ></Input>
                                            </div>
                                                <div className="text-center  mt-4">
                                                    <Button type="submit" color="danger">
                                                        <Trans>Submit</Trans>
                                                    </Button>
                                                </div>
                                            </Row>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditAddress
