import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/dashboard">{props.t("Dashboard")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="dripicons-user-group"></i>
                <span>{props.t("Users")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/users">{props.t("List Users")}</Link>
                </li>
                <li>
                  <Link to="/users/add">{props.t("Add User")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="dripicons-user-group"></i>
                <span>{props.t("Addresses")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/address">{props.t("List Addresses")}</Link>
                </li>
                <li>
                  <Link to="/address/add">{props.t("Add Address")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-globe"></i>
                <span>{props.t("Countries")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/countries">{props.t("List Countries")}</Link>
                </li>
                <li>
                  <Link to="/countries/add">{props.t("Add Country")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-truck"></i>
                <span>{props.t("Vehicles")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/vehicles">{props.t("List Vehicles")}</Link>
                </li>
                <li>
                  <Link to="/vehicles/add">{props.t("Add Vehicle")}</Link>
                </li>
                <li>
                  <Link to="/vehicle-categories">{props.t("List Vehicle Categories")}</Link>
                </li>
                <li>
                  <Link to="/vehicle-categories/add">{props.t("Add Vehicle Category")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Shipment Types")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/shipment-types">{props.t("List Shipment Types")}</Link>
                </li>
                <li>
                  <Link to="/shipment-types/add">{props.t("Add Shipment Type")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Drivers")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/drivers">{props.t("List Drivers")}</Link>
                </li>
                <li>
                  <Link to="/drivers/add">{props.t("Add Driver")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>{props.t("Items")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/items">{props.t("List Items")}</Link>
                </li>
                <li>
                  <Link to="/items/add">{props.t("Add Item")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>{props.t("Services")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/services">{props.t("List Services")}</Link>
                </li>
                <li>
                  <Link to="/services/add">{props.t("Add Service")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>{props.t("Order Status")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/status">{props.t("List Status")}</Link>
                </li>
                <li>
                  <Link to="/status/add">{props.t("Add Status")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>{props.t("Orders")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/orders">{props.t("List Orders")}</Link>
                </li>
                <li>
                  <Link to="/orders/add">{props.t("Add Order")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Screens")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/screens">{props.t("List Screens")}</Link>
                </li>
                <li>
                  <Link to="/screens/add">{props.t("Add Screen")}</Link>
                </li>
                <li>
                  <Link to="/sections">{props.t("Screen Sections")}</Link>
                </li>
                <li>
                  <Link to="/sections/add">{props.t("Add Screen Section")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="dripicons-view-thumb"></i>
                <span>{props.t("Management")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/roles">{props.t("Roles")}</Link>
                </li>
                <li>
                  <Link to="/application/setup">{props.t("Application Setup")}</Link>
                </li>
                <li>
                  <Link to="/categories">{props.t("Delivery Types")}</Link>
                </li>
                <li>
                  <Link to="/banners">{props.t("Banners")}</Link>
                </li>
                <li>
                  <Link to="/offers">{props.t("Offers")}</Link>
                </li>
                <li>
                  <Link to="/tickets">{props.t("Tickets")}</Link>
                </li>
                <li>
                  <Link to="/terms">{props.t("Terms & Conditions")}</Link>
                </li>
                <li>
                  <Link to="/faqs">{props.t("FAQs")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/logout" >
                <i className="dripicons-power" />
                <span>{props.t("Logout")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
